<template>
  <div class="component-simulator-mortage-basic">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title') }}</h2>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_basic')"></div>
            </div>

            <b-row no-gutters>

              <!-- AMOUNT -->
              <b-col class="col-lg-10">
                <b-form-group
                  label-class="FormLabels"
                  label-for="amount_per_month"
                  label-cols="12"
                  label-align="left"
                  :label="$t(translationPath + 'amount')"
                >
                  <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                    <currency-input
                      ref="CurrencyInputAmountPerMonth"
                      id="amount_per_month"
                      v-model.number="amount"
                      :precision="0"
                      :class="$v.amount.$error ? 'is-invalid' : ''"
                      :state="$v.amount.$dirty ? !$v.amount.$error : null"
                      @input="$v.amount.$touch()"
                    />
                  </b-col>
                  <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                    <b-form-input
                      id="amount_per_month_slider"
                      class="slider ml-2"
                      type="range"
                      min="0"
                      max="10000000"
                      step="100000"
                      v-model.number="amount"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>

              <!-- Interest -->
              <b-col class="col-lg-10">
                <b-form-group
                  label-class="FormLabels"
                  label-for="interest"
                  label-cols="12"
                  label-align="left"
                  :label="$t(translationPath + 'interest')"
                >
                  <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                    <percentage-input
                      ref="CurrencyInputInterest"
                      id="interest"
                      v-model.number="interest"
                      :precision="2"
                      :class="$v.interest.$error ? 'is-invalid' : ''"
                      :state="$v.interest.$dirty ? !$v.interest.$error : null"
                      @input="$v.interest.$touch()"
                    />
                  </b-col>
                  <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                    <b-form-input
                      id="interest_slider"
                      class="slider ml-2"
                      type="range"
                      min="0"
                      max="10"
                      step="0.25"
                      v-model.number="interest"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>

              <!-- Persons -->
              <b-col class="col-lg-10">
                <b-form-group
                  label-class="FormLabels"
                  label-for="persons"
                  label-cols="12"
                  label-align="left"
                  :label="$t(translationPath + 'persons')"
                >
                  <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                    <custom-number-input
                      ref="Persons"
                      id="persons"
                      v-model.number="persons"
                      :suffix="' st'"
                      :precision="0"
                      :min="1"
                      :max="2"
                      :step="1"
                      :input-class="$v.persons.$error ? 'is-invalid' : ''"
                      :state="$v.persons.$dirty ? !$v.persons.$error : null"
                      @input="$v.persons.$touch()"
                    />
                  </b-col>
                  <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                    <b-form-input
                      id="persons_slider"
                      class="slider ml-2"
                      type="range"
                      min="1"
                      max="2"
                      step="1"
                      v-model.number="persons"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>

            </b-row>

            <h3 class="mt-4">Detaljerad beräkning</h3>
            <b-table-simple hover striped class="mt-3 detailed_calculation_table">
              <b-tbody>
                <b-tr>
                  <b-td><b>Lånebelopp</b></b-td>
                  <b-td colspan="2">{{aData.amountFormatted}}</b-td>
                </b-tr>
                <b-tr>
                  <b-td><b>Räntesats</b></b-td>
                  <b-td colspan="2">{{this.interest}} %</b-td>
                </b-tr>
                <b-tr>
                  <b-td></b-td>
                  <b-td><b>Per år</b></b-td>
                  <b-td><b>Per månad</b></b-td>
                </b-tr>
                <b-tr>
                  <b-td><b>Räntebelopp (före ränteavdrag)</b></b-td>
                  <b-td>{{aData.perYearFormatted}}</b-td>
                  <b-td>{{aData.perMonthFormatted}}</b-td>
                </b-tr>
                <b-tr>
                  <b-td><b>Ränteavdrag</b></b-td>
                  <b-td>{{aData.perYearDeductionFormatted}}</b-td>
                  <b-td>{{aData.perMonthDeductionFormatted}}</b-td>
                </b-tr>
                <b-tr>
                  <b-td><b>Räntebelopp (efter ränteavdrag)</b></b-td>
                  <b-td>{{aData.perYearAfterDeductionFormatted}}</b-td>
                  <b-td>{{aData.perMonthAfterDeductionFormatted}}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, integer, minValue } from 'vuelidate/lib/validators'
import CurrencyInput from '@/components/common/CurrencyInput'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import CustomNumberInput from '@/components/common/CustomNumberInput'
import formatAmount from '@/assets/filters/formatAmount'
import PercentageInput from '@/components/common/PercentageInput.vue'

export default {
  name: 'MortageSimulatorBasic',
  mixins: [useComponentCurrencyInputMixin],
  components: { PercentageInput, CurrencyInput, CustomNumberInput },
  data () {
    return {
      translationPath: 'reports.simulators.mortage.submenu.basic.',
      amount: 1000000,
      interest: 4,
      persons: 2,
      aData: {
        amountFormatted: '1 000 000 kr',
        perYearFormatted: '40 000 kr',
        perYearDeductionFormatted: '12 000 kr',
        perYearAfterDeductionFormatted: '28 000 kr',
        perMonthFormatted: '3 333 kr',
        perMonthDeductionFormatted: '1 000 kr',
        perMonthAfterDeductionFormatted: '2 333 kr'
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    emitBasicData () {
      this.aData.perYearRaw = parseInt((this.amount * this.interest / 100).toFixed())
      this.aData.perYearFormatted = this.formatAmount(this.aData.perYearRaw, 1)
      this.aData.perMonthRaw = parseInt((this.aData.perYearRaw / 12).toFixed())
      this.aData.perMonthFormatted = this.formatAmount(this.aData.perMonthRaw, 1)

      const iLimit = 100000 * this.persons
      const iTmpAmtGt100k = (this.aData.perYearRaw - iLimit) > 0 ? (this.aData.perYearRaw - iLimit) : 0
      const iTmpAmtLtEq100k = this.aData.perYearRaw > iLimit ? iLimit : this.aData.perYearRaw

      this.aData.perYearDeductionRaw = iTmpAmtLtEq100k * 0.3 + iTmpAmtGt100k * 0.21
      this.aData.perYearDeductionFormatted = this.formatAmount(this.aData.perYearDeductionRaw, 1)
      this.aData.perMonthDeductionRaw = this.aData.perYearDeductionRaw / 12
      this.aData.perMonthDeductionFormatted = this.formatAmount(this.aData.perYearDeductionRaw / 12, 1)

      this.aData.perYearAfterDeductionRaw = parseInt((this.aData.perYearRaw - this.aData.perYearDeductionRaw).toFixed())
      this.aData.perYearAfterDeductionFormatted = this.formatAmount(this.aData.perYearAfterDeductionRaw, 1)
      this.aData.perMonthAfterDeductionRaw = parseInt((this.aData.perYearAfterDeductionRaw / 12).toFixed())
      this.aData.perMonthAfterDeductionFormatted = this.formatAmount(this.aData.perMonthAfterDeductionRaw, 1)

      this.aData.amountFormatted = this.formatAmount(this.amount, 1)

      this.$emit('basic-interest-data', { amount: this.amount, amountFormatted: this.aData.amountFormatted, interest: this.interest, result: this.aData })
    },
    formatAmount (amount, showCurrencyLabel = true) {
      if (this.currentCOA.locale) {
        amount = formatAmount(
          amount,
          this.currentCOA.locale,
          this.currentCOA.currency,
          this.currentCOA.currency_iso,
          showCurrencyLabel,
          0
        )
      }
      return amount
    }
  },
  validations: {
    amount: {
      required,
      integer,
      minValue: minValue(0)
    },
    interest: {
      required,
      minValue: minValue(0)
    },
    persons: {
      required,
      minValue: minValue(0)
    }
  },
  watch: {
    amount: {
      handler () {
        this.emitBasicData()
        this.rerenderCurrencyInputs()
      }
    },
    interest: {
      handler () {
        this.emitBasicData()
        this.rerenderCurrencyInputs()
      }
    },
    persons: {
      handler () {
        this.emitBasicData()
        this.rerenderCurrencyInputs()
      }
    }
  }
}
</script>

<style lang="scss">
.component-simulator-mortage-basic{
  .detailed_calculation_table{
    td{
      &:nth-child(1) {
        width:400px;
      }
    }
  }
}
</style>
