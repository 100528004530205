<template>
  <div class="component-reports-simulators-mortage-simulator">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">

            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <!-- <mortage-simulator-submenu :active="activeTab" @tab-switched="onTabSwitched"/> -->
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter'}">

        <!-- Periods / Filter tab has different height -->
        <div class="pb-5" style="width:100%;height:400px;">

          <div class="chart_kpi_wrapper">
            <b-row>
              <b-col class="col-12">
                <span class="kpi_title">{{$t(translationPath + 'interest_per_year', {amount: aBasic.amountFormatted, interest: aBasic.interest })}}</span><br />
                <span class="kpi_value">{{aBasic.interest_per_year_after_interest_deduction}}</span><br />
              </b-col>
              <b-col class="col-12">
                <span class="kpi_title">{{$t(translationPath + 'divided_per_month')}}</span><br />
                <span class="kpi_value">{{aBasic.interest_per_month_after_interest_deduction}}</span><br />
              </b-col>
            </b-row>
          </div>

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->

      <mortage-simulator-basic v-if="activeTab === 'simple'"
        @basic-interest-data="onBasicSimulatorChange"
      />

    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
// import MortageSimulatorSubmenu from './inc/MortageSimulatorSubmenu'
import MortageSimulatorBasic from './basic/MortageSimulatorBasic'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'MortageSimulator',
  mixins: [titleMixins],
  components: { MortageSimulatorBasic },
  filters: { formatAmount },
  data () {
    return {
      validRouteParams: ['simple', 'advanced'],
      activeTab: 'simple',
      translationPath: 'reports.simulators.mortage.',
      busyState: true,
      chartToDisplay: 'bar-chart',
      aBasic: {
        interest_per_month_after_interest_deduction: '2 333 kr',
        interest_per_year_after_interest_deduction: '28 000 kr',
        amountFormatted: '1 000 000 kr',
        interest: 4
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      let aBreadcrumb = []

      aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/performance' },
        { text: this.$t('reports._common.simulator_reports'), to: '/reports/simulators' },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    /**
     * Handle emitted data from period_selector
     */
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) !== -1) {
        this.$router.push({ params: { tab: newtab } })
      } else if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) === -1) {
        this.$router.push({ params: { tab: 'summary' } })
      }
    },
    onBasicSimulatorChange (data) {
      this.aBasic.interest_per_month_after_interest_deduction = data.result.perMonthAfterDeductionFormatted
      this.aBasic.interest_per_year_after_interest_deduction = data.result.perYearAfterDeductionFormatted
      this.aBasic.amountFormatted = data.amountFormatted
      this.aBasic.interest = data.interest
    }
  },
  mounted () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-reports-simulators-mortage-simulator{
  width: 100%;
  .kpi_title{ font-size: 150%}
  .kpi_value{ font-size: 350%; font-weight: 900; }

  .chart_kpi_wrapper{width:95%;margin: auto;}
}
</style>
